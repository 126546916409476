const YoutubeSvg = () => {

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd"
				d="M11.9999 4.1998C10.2359 4.1998 8.54733 4.37411 6.98627 4.69232C4.93705 5.11004 4.20325 5.28496 3.46905 6.22893C2.73125 7.17754 2.6999 8.24458 2.6999 11.0363V12.9634C2.6999 15.755 2.73125 16.8221 3.46905 17.7707C4.20325 18.7147 4.93705 18.8896 6.98627 19.3073C8.54733 19.6255 10.2359 19.7998 11.9999 19.7998C13.7639 19.7998 15.4525 19.6255 17.0135 19.3073C19.0627 18.8896 19.7965 18.7147 20.5307 17.7707C21.2685 16.8221 21.2999 15.755 21.2999 12.9634V11.0363C21.2999 8.24458 21.2685 7.17754 20.5307 6.22894C19.7965 5.28496 19.0627 5.11004 17.0135 4.69232C15.4525 4.37411 13.7639 4.1998 11.9999 4.1998ZM6.70664 3.32053C8.36224 2.98305 10.1446 2.7998 11.9999 2.7998C13.8552 2.7998 15.6375 2.98305 17.2932 3.32053C17.3337 3.32879 17.3739 3.33698 17.4138 3.34511C19.3142 3.73197 20.5595 3.98549 21.6358 5.36942C22.7013 6.7393 22.7008 8.32704 22.6999 10.8235C22.6999 10.8937 22.6999 10.9646 22.6999 11.0363V12.9634C22.6999 13.035 22.6999 13.1059 22.6999 13.1761C22.7008 15.6726 22.7013 17.2603 21.6358 18.6302C20.5595 20.0141 19.3142 20.2676 17.4138 20.6545C17.3739 20.6626 17.3337 20.6708 17.2932 20.6791C15.6375 21.0166 13.8552 21.1998 11.9999 21.1998C10.1446 21.1998 8.36224 21.0166 6.70664 20.6791C6.66612 20.6708 6.62589 20.6626 6.58596 20.6545C4.68564 20.2676 3.44034 20.0141 2.36395 18.6302C1.2985 17.2603 1.29902 15.6726 1.29985 13.1761C1.29987 13.1059 1.2999 13.035 1.2999 12.9634V11.0363C1.2999 10.9646 1.29987 10.8937 1.29985 10.8235C1.29902 8.32704 1.2985 6.7393 2.36395 5.36942C3.44034 3.98549 4.68564 3.73197 6.58597 3.34511C6.6259 3.33698 6.66612 3.32879 6.70664 3.32053Z"
				fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd"
				d="M13.7446 9.14171L13.8216 9.18417C14.5781 9.60097 15.199 9.94312 15.645 10.2596C16.0918 10.5766 16.5053 10.9626 16.6419 11.5204C16.7192 11.8359 16.7192 12.1637 16.6419 12.4792C16.5053 13.037 16.0918 13.423 15.645 13.74C15.199 14.0565 14.5781 14.3986 13.8217 14.8154L13.7446 14.8579C12.9181 15.3134 12.2458 15.6838 11.6933 15.9126C11.1329 16.1447 10.5676 16.2884 9.99523 16.1384C9.66755 16.0525 9.36021 15.9001 9.09779 15.688C8.62038 15.302 8.44899 14.7478 8.37325 14.1781C8.29985 13.6259 8.29987 12.9102 8.2999 12.0502V11.9494C8.29987 11.0894 8.29985 10.3737 8.37325 9.82153C8.44899 9.2518 8.62038 8.6976 9.09779 8.31163C9.36021 8.09948 9.66754 7.94709 9.99523 7.86121C10.5676 7.7112 11.1329 7.85492 11.6933 8.087C12.2458 8.31581 12.9181 8.68626 13.7446 9.14171ZM11.1577 9.38048C10.6854 9.18489 10.4735 9.18316 10.3502 9.21547C10.2066 9.25309 10.08 9.31786 9.97798 9.40033C9.9174 9.44931 9.8198 9.56406 9.76104 10.006C9.70138 10.4548 9.6999 11.0763 9.6999 11.9998C9.6999 12.9233 9.70138 13.5448 9.76104 13.9936C9.8198 14.4355 9.9174 14.5503 9.97798 14.5993C10.08 14.6817 10.2066 14.7465 10.3502 14.7841C10.4735 14.8165 10.6854 14.8147 11.1577 14.6191C11.6294 14.4238 12.2332 14.0922 13.1069 13.6108C13.9121 13.1671 14.4619 12.8629 14.8349 12.5982C15.2148 12.3287 15.2704 12.1942 15.2821 12.1461C15.3058 12.0494 15.3058 11.9502 15.2821 11.8535C15.2704 11.8054 15.2148 11.6709 14.8349 11.4014C14.4619 11.1367 13.9121 10.8325 13.1069 10.3888C12.2332 9.90738 11.6294 9.57583 11.1577 9.38048Z"
				fill="white"/>
		</svg>
	);
}

export default YoutubeSvg;
