const InstagramSvg = () => {

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd"
				d="M11.9462 1.7998H12.0534C14.2472 1.79979 15.965 1.79978 17.3051 1.97995C18.6762 2.16429 19.7562 2.54871 20.6035 3.39607C21.4509 4.24343 21.8353 5.32338 22.0197 6.69449C22.1998 8.03456 22.1998 9.75244 22.1998 11.9461V12.0535C22.1998 14.2472 22.1998 15.965 22.0197 17.3051C21.8353 18.6762 21.4509 19.7562 20.6035 20.6035C19.7562 21.4509 18.6762 21.8353 17.3051 22.0197C15.965 22.1998 14.2472 22.1998 12.0535 22.1998H11.9461C9.75244 22.1998 8.03456 22.1998 6.69449 22.0197C5.32338 21.8353 4.24343 21.4509 3.39607 20.6035C2.54871 19.7562 2.16429 18.6762 1.97995 17.3051C1.79978 15.965 1.79979 14.2472 1.7998 12.0534V11.9462C1.79979 9.75246 1.79978 8.03457 1.97995 6.69449C2.16429 5.32338 2.54871 4.24343 3.39607 3.39607C4.24343 2.54871 5.32338 2.16429 6.69449 1.97995C8.03457 1.79978 9.75246 1.79979 11.9462 1.7998ZM6.88104 3.36747C5.66517 3.53094 4.92991 3.84214 4.38602 4.38602C3.84214 4.92991 3.53094 5.66517 3.36747 6.88104C3.20129 8.11704 3.19981 9.74085 3.19981 11.9998C3.19981 14.2588 3.20129 15.8826 3.36747 17.1186C3.53094 18.3344 3.84214 19.0697 4.38602 19.6136C4.92991 20.1575 5.66517 20.4687 6.88104 20.6321C8.11704 20.7983 9.74085 20.7998 11.9998 20.7998C14.2588 20.7998 15.8826 20.7983 17.1186 20.6321C18.3344 20.4687 19.0697 20.1575 19.6136 19.6136C20.1575 19.0697 20.4687 18.3344 20.6321 17.1186C20.7983 15.8826 20.7998 14.2588 20.7998 11.9998C20.7998 9.74085 20.7983 8.11704 20.6321 6.88104C20.4687 5.66517 20.1575 4.92991 19.6136 4.38602C19.0697 3.84214 18.3344 3.53094 17.1186 3.36747C15.8826 3.20129 14.2588 3.19981 11.9998 3.19981C9.74085 3.19981 8.11704 3.20129 6.88104 3.36747Z"
				fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd"
				d="M11.9998 8.19981C9.90112 8.19981 8.19981 9.90112 8.19981 11.9998C8.19981 14.0985 9.90112 15.7998 11.9998 15.7998C14.0985 15.7998 15.7998 14.0985 15.7998 11.9998C15.7998 9.90112 14.0985 8.19981 11.9998 8.19981ZM6.79981 11.9998C6.79981 9.12792 9.12792 6.79981 11.9998 6.79981C14.8717 6.79981 17.1998 9.12792 17.1998 11.9998C17.1998 14.8717 14.8717 17.1998 11.9998 17.1998C9.12792 17.1998 6.79981 14.8717 6.79981 11.9998Z"
				fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd"
				d="M18.5076 6.4998C18.5076 7.05209 18.0599 7.4998 17.5076 7.4998H17.4986C16.9464 7.4998 16.4986 7.05209 16.4986 6.4998C16.4986 5.94752 16.9464 5.4998 17.4986 5.4998H17.5076C18.0599 5.4998 18.5076 5.94752 18.5076 6.4998Z"
				fill="white"/>
		</svg>
	);
}

export default InstagramSvg;
