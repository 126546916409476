const LogoSvg = ( { color } ) => {

	return (
		<svg width="118" height="24" viewBox="0 0 118 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4771_109091)">
				<path
					d="M26.6587 9.30724V5.93224C26.7669 4.71454 27.257 3.56214 28.0591 2.63953C28.8612 1.71692 29.9342 1.07127 31.125 0.794744C31.5045 0.71991 31.8983 0.790531 32.2282 0.992587C32.5581 1.19464 32.7999 1.51336 32.9057 1.88545C33.0114 2.25755 32.9734 2.65581 32.7992 3.00118C32.6249 3.34654 32.3271 3.61375 31.965 3.74974C31.4821 3.91078 31.0523 4.20053 30.7219 4.58776C30.3915 4.97499 30.173 5.44503 30.09 5.94724H32.6737C33.0785 5.94724 33.4667 6.10804 33.753 6.39427C34.0392 6.6805 34.2 7.06871 34.2 7.47349V7.49974C34.2 7.90453 34.0392 8.29274 33.753 8.57897C33.4667 8.86519 33.0785 9.02599 32.6737 9.02599H30.165V18.026C30.176 18.263 30.1387 18.4998 30.0556 18.7221C29.9724 18.9443 29.8451 19.1474 29.6812 19.3191C29.5173 19.4907 29.3203 19.6273 29.1022 19.7206C28.884 19.814 28.6492 19.8621 28.4119 19.8621C28.1746 19.8621 27.9397 19.814 27.7216 19.7206C27.5034 19.6273 27.3064 19.4907 27.1425 19.3191C26.9787 19.1474 26.8513 18.9443 26.7681 18.7221C26.685 18.4998 26.6478 18.263 26.6587 18.026V9.30724Z"
					fill={ color || '#013660' }/>
				<path
					d="M38.25 11.6588C38.2366 10.3609 38.6679 9.09759 39.4723 8.07896C40.2767 7.06034 41.4057 6.34786 42.6713 6.06006C43.0636 5.97416 43.474 6.04065 43.8191 6.24605C44.1642 6.45146 44.4184 6.78041 44.53 7.16623C44.6416 7.55206 44.6023 7.96588 44.4201 8.32381C44.2379 8.68174 43.9264 8.95701 43.5488 9.09381C42.4238 9.45006 41.6963 10.4363 41.6963 12.0938V18.0938C41.7072 18.3269 41.6707 18.5597 41.589 18.7782C41.5073 18.9968 41.3821 19.1964 41.221 19.3652C41.06 19.534 40.8663 19.6683 40.6518 19.7601C40.4373 19.8519 40.2065 19.8992 39.9732 19.8992C39.7399 19.8992 39.509 19.8519 39.2945 19.7601C39.08 19.6683 38.8864 19.534 38.7253 19.3652C38.5642 19.1964 38.4391 18.9968 38.3574 18.7782C38.2757 18.5597 38.2392 18.3269 38.25 18.0938V11.6588Z"
					fill={ color || '#013660' }/>
				<path
					d="M51.716 5.95117C52.1775 5.95117 52.6201 6.13449 52.9464 6.46081C53.2727 6.78712 53.456 7.2297 53.456 7.69117L53.426 18.0562C53.4369 18.2912 53.4001 18.526 53.3176 18.7464C53.2352 18.9668 53.1089 19.1682 52.9465 19.3384C52.784 19.5086 52.5887 19.6441 52.3724 19.7366C52.156 19.8292 51.9232 19.8769 51.6879 19.8769C51.4526 19.8769 51.2197 19.8292 51.0034 19.7366C50.7871 19.6441 50.5918 19.5086 50.4293 19.3384C50.2668 19.1682 50.1405 18.9668 50.0581 18.7464C49.9757 18.526 49.9388 18.2912 49.9498 18.0562L49.9798 7.69117C49.9793 7.46285 50.0238 7.23667 50.1108 7.02559C50.1979 6.8145 50.3257 6.62266 50.487 6.46103C50.6482 6.29941 50.8398 6.17118 51.0507 6.08369C51.2616 5.9962 51.4877 5.95117 51.716 5.95117Z"
					fill={ color || '#013660' }/>
				<path
					d="M58.9237 16.65C59.1108 16.32 59.4196 16.0763 59.7841 15.9712C60.1485 15.8661 60.5397 15.9078 60.8737 16.0875C61.7198 16.5705 62.6681 16.8468 63.6412 16.8937C64.365 16.8937 65.6662 16.7137 65.6662 15.7162C65.6662 13.6575 58.8637 14.1112 58.8637 9.91125C58.875 7.2225 61.4025 6 63.75 6C65.0294 6.03596 66.2847 6.3562 67.425 6.9375C67.604 7.02516 67.764 7.14721 67.8958 7.2967C68.0277 7.44619 68.1288 7.62018 68.1934 7.80874C68.2579 7.9973 68.2848 8.19674 68.2723 8.39567C68.2598 8.5946 68.2083 8.78912 68.1206 8.96813C68.0329 9.14713 67.9109 9.30712 67.7614 9.43896C67.6119 9.5708 67.4379 9.6719 67.2494 9.73649C67.0608 9.80109 66.8614 9.82791 66.6624 9.81543C66.4635 9.80295 66.269 9.75141 66.09 9.66375C65.3718 9.25598 64.5732 9.01024 63.75 8.94375C63.2062 8.94375 62.3887 9.06375 62.3887 9.76125C62.3887 11.8763 69.1912 11.0888 69.1912 15.6863C69.1912 18.555 66.2287 19.8113 63.72 19.8113C62.2417 19.7783 60.7928 19.3925 59.4937 18.6863C59.3236 18.5975 59.1731 18.4753 59.0512 18.3271C58.9293 18.1788 58.8385 18.0075 58.7842 17.8234C58.73 17.6393 58.7134 17.4462 58.7354 17.2555C58.7574 17.0649 58.8177 16.8806 58.9125 16.7137L58.9237 16.65Z"
					fill={ color || '#013660' }/>
				<path
					d="M73.3424 16.6505C73.5287 16.3207 73.8368 16.0771 74.2007 15.972C74.5645 15.8668 74.9551 15.9085 75.2886 16.088C76.1347 16.571 77.083 16.8472 78.0561 16.8942C78.7836 16.8942 80.0811 16.7142 80.0811 15.7167C80.0811 13.658 73.2824 14.1117 73.2824 9.91172C73.2824 7.22297 75.8211 6.01172 78.1761 6.01172C79.4555 6.04768 80.7109 6.36792 81.8511 6.94922C82.0301 7.03663 82.1902 7.15844 82.3221 7.3077C82.454 7.45697 82.5553 7.63075 82.62 7.81914C82.6848 8.00752 82.7118 8.20682 82.6996 8.40566C82.6873 8.60449 82.636 8.79896 82.5486 8.97797C82.4612 9.15698 82.3394 9.31702 82.1901 9.44895C82.0409 9.58088 81.8671 9.68212 81.6787 9.74689C81.4903 9.81166 81.291 9.83868 81.0922 9.82643C80.8933 9.81418 80.6989 9.76288 80.5199 9.67547C79.7921 9.25996 78.9815 9.01027 78.1461 8.94422C77.6024 8.94422 76.7886 9.06422 76.7886 9.76172C76.7886 11.8767 83.5874 11.0892 83.5874 15.6867C83.5874 18.5555 80.6249 19.8117 78.1161 19.8117C76.6627 19.7825 75.2367 19.4112 73.9536 18.728C73.776 18.6409 73.618 18.5186 73.489 18.3687C73.36 18.2188 73.2628 18.0442 73.2032 17.8556C73.1436 17.667 73.1229 17.4683 73.1423 17.2715C73.1617 17.0746 73.2208 16.8838 73.3161 16.7105L73.3424 16.6505Z"
					fill={ color || '#013660' }/>
				<path
					d="M87.09 12.9035C87.0959 11.5403 87.506 10.2095 88.2682 9.0793C89.0305 7.94915 90.1108 7.07043 91.3725 6.55425C92.6342 6.03806 94.0206 5.90761 95.3565 6.17938C96.6923 6.45114 97.9176 7.11293 98.8773 8.08104C99.837 9.04916 100.488 10.2801 100.748 11.6183C101.008 12.9564 100.866 14.3417 100.339 15.5988C99.8116 16.856 98.9235 17.9286 97.7867 18.681C96.65 19.4334 95.3157 19.8319 93.9525 19.826C93.0469 19.8235 92.1507 19.6423 91.3153 19.2928C90.4799 18.9433 89.7217 18.4324 89.0842 17.7893C88.4466 17.1462 87.9423 16.3835 87.6001 15.5451C87.2579 14.7067 87.0845 13.809 87.09 12.9035ZM94.0125 16.5297C94.7059 16.5297 95.3838 16.3241 95.9604 15.9388C96.537 15.5535 96.9864 15.0059 97.2518 14.3652C97.5172 13.7246 97.5866 13.0196 97.4513 12.3394C97.3161 11.6593 96.9821 11.0345 96.4918 10.5442C96.0014 10.0538 95.3766 9.71986 94.6965 9.58458C94.0164 9.44929 93.3114 9.51872 92.6707 9.7841C92.03 10.0495 91.4824 10.4989 91.0971 11.0755C90.7119 11.6521 90.5062 12.33 90.5062 13.0235C90.5082 13.9528 90.8782 14.8434 91.5354 15.5006C92.1925 16.1577 93.0832 16.5277 94.0125 16.5297Z"
					fill={ color || '#013660' }/>
				<path
					d="M104.831 11.7567C104.831 8.67422 107.4 6.01172 111.15 6.01172C114.9 6.01172 117.495 8.67422 117.495 11.7567V18.0567C117.506 18.2918 117.469 18.5266 117.387 18.747C117.304 18.9674 117.178 19.1688 117.015 19.339C116.853 19.5092 116.658 19.6446 116.441 19.7372C116.225 19.8298 115.992 19.8775 115.757 19.8775C115.522 19.8775 115.289 19.8298 115.072 19.7372C114.856 19.6446 114.661 19.5092 114.498 19.339C114.336 19.1688 114.21 18.9674 114.127 18.747C114.045 18.5266 114.008 18.2918 114.019 18.0567V12.2705C114.019 10.1555 112.841 8.94422 111.15 8.94422C109.459 8.94422 108.307 10.1555 108.307 12.2705V18.0567C108.318 18.2918 108.282 18.5266 108.199 18.747C108.117 18.9674 107.99 19.1688 107.828 19.339C107.665 19.5092 107.47 19.6446 107.254 19.7372C107.038 19.8298 106.805 19.8775 106.569 19.8775C106.334 19.8775 106.101 19.8298 105.885 19.7372C105.669 19.6446 105.473 19.5092 105.311 19.339C105.148 19.1688 105.022 18.9674 104.94 18.747C104.857 18.5266 104.82 18.2918 104.831 18.0567V11.7567Z"
					fill={ color || '#013660' }/>
				<path
					d="M16.47 16.1246C16.47 16.4326 16.3787 16.7336 16.2075 16.9896C16.0363 17.2456 15.7931 17.4451 15.5085 17.5628C15.2239 17.6804 14.9108 17.711 14.6088 17.6507C14.3068 17.5903 14.0295 17.4417 13.812 17.2237C13.5945 17.0057 13.4466 16.728 13.387 16.4259C13.3274 16.1238 13.3587 15.8108 13.4771 15.5265C13.5955 15.2422 13.7955 14.9994 14.0519 14.8288C14.3083 14.6583 14.6096 14.5676 14.9175 14.5684C15.1217 14.5684 15.3239 14.6086 15.5125 14.6869C15.7011 14.7652 15.8724 14.8798 16.0167 15.0244C16.1609 15.169 16.2751 15.3406 16.3529 15.5293C16.4307 15.7181 16.4705 15.9204 16.47 16.1246Z"
					fill={ color || '#013660' }/>
				<path
					d="M9.28865 16.1246C9.28865 16.4324 9.19738 16.7333 9.02637 16.9892C8.85537 17.2451 8.61232 17.4446 8.32795 17.5624C8.04358 17.6802 7.73067 17.711 7.42879 17.651C7.12691 17.5909 6.84961 17.4427 6.63196 17.225C6.41432 17.0074 6.2661 16.7301 6.20605 16.4282C6.146 16.1263 6.17682 15.8134 6.29461 15.5291C6.4124 15.2447 6.61187 15.0016 6.86779 14.8306C7.12372 14.6596 7.4246 14.5684 7.7324 14.5684C7.93677 14.5684 8.13914 14.6086 8.32795 14.6868C8.51676 14.765 8.68832 14.8797 8.83283 15.0242C8.97734 15.1687 9.09198 15.3402 9.17019 15.5291C9.24839 15.7179 9.28865 15.9202 9.28865 16.1246Z"
					fill={ color || '#013660' }/>
				<path
					d="M16.44 8.88746C16.4408 9.19542 16.3501 9.49667 16.1796 9.75309C16.009 10.0095 15.7662 10.2096 15.4819 10.3279C15.1976 10.4463 14.8846 10.4776 14.5825 10.418C14.2803 10.3584 14.0027 10.2105 13.7847 9.99297C13.5666 9.77548 13.418 9.49819 13.3577 9.1962C13.2973 8.89422 13.3279 8.58112 13.4456 8.29653C13.5633 8.01194 13.7628 7.76867 14.0188 7.5975C14.2748 7.42633 14.5758 7.33496 14.8838 7.33496C15.2959 7.33496 15.6911 7.49841 15.9829 7.78945C16.2746 8.08049 16.439 8.47537 16.44 8.88746Z"
					fill={ color || '#013660' }/>
				<path
					d="M9.25495 8.88746C9.25569 9.19542 9.16505 9.49667 8.9945 9.75309C8.82395 10.0095 8.58116 10.2096 8.29686 10.3279C8.01256 10.4463 7.69953 10.4776 7.3974 10.418C7.09527 10.3584 6.81762 10.2105 6.5996 9.99297C6.38158 9.77548 6.23299 9.49819 6.17264 9.1962C6.11228 8.89422 6.14288 8.58112 6.26056 8.29653C6.37824 8.01194 6.5777 7.76867 6.83371 7.5975C7.08971 7.42633 7.39075 7.33496 7.6987 7.33496C8.1108 7.33496 8.50607 7.49841 8.79781 7.78945C9.08956 8.08049 9.25396 8.47537 9.25495 8.88746Z"
					fill={ color || '#013660' }/>
				<path
					d="M19.8 12.4986C19.8 12.7508 19.7252 12.9973 19.5851 13.207C19.445 13.4167 19.2459 13.5801 19.0129 13.6766C18.7799 13.7731 18.5236 13.7983 18.2763 13.7491C18.0289 13.6999 17.8018 13.5785 17.6234 13.4002C17.4451 13.2219 17.3237 12.9947 17.2745 12.7474C17.2253 12.5 17.2506 12.2437 17.3471 12.0107C17.4436 11.7777 17.607 11.5786 17.8166 11.4385C18.0263 11.2984 18.2728 11.2236 18.525 11.2236C18.8632 11.2236 19.1875 11.358 19.4266 11.5971C19.6657 11.8362 19.8 12.1605 19.8 12.4986Z"
					fill={ color || '#013660' }/>
				<path
					d="M5.3625 12.4986C5.3625 12.751 5.28763 12.9976 5.14736 13.2074C5.0071 13.4171 4.80775 13.5805 4.57455 13.6769C4.34136 13.7733 4.08481 13.7984 3.83738 13.7489C3.58995 13.6994 3.36277 13.5776 3.18461 13.3989C3.00645 13.2202 2.88532 12.9926 2.83654 12.7451C2.78777 12.4975 2.81356 12.241 2.91063 12.0081C3.00771 11.7752 3.17171 11.5763 3.38188 11.4367C3.59204 11.297 3.83892 11.2229 4.09125 11.2236C4.25851 11.2236 4.42412 11.2566 4.5786 11.3208C4.73308 11.3849 4.87339 11.4789 4.99149 11.5973C5.10958 11.7157 5.20315 11.8563 5.26681 12.011C5.33048 12.1657 5.36299 12.3314 5.3625 12.4986Z"
					fill={ color || '#013660' }/>
				<path
					d="M13.3274 12.4988C13.3274 12.8987 13.2088 13.2897 12.9865 13.6221C12.7642 13.9546 12.4483 14.2137 12.0788 14.3665C11.7092 14.5194 11.3026 14.5592 10.9105 14.4809C10.5183 14.4026 10.1581 14.2098 9.8756 13.9267C9.59307 13.6436 9.40086 13.2831 9.32329 12.8908C9.24573 12.4985 9.28629 12.092 9.43985 11.7227C9.59341 11.3534 9.85306 11.038 10.1859 10.8164C10.5188 10.5947 10.91 10.4768 11.3099 10.4775C11.8453 10.4785 12.3585 10.6919 12.7367 11.0709C13.115 11.4498 13.3274 11.9634 13.3274 12.4988Z"
					fill={ color || '#013660' }/>
				<path
					d="M22.6162 12.4986C22.6162 12.6351 22.5757 12.7685 22.4999 12.8819C22.4241 12.9954 22.3163 13.0838 22.1903 13.1361C22.0642 13.1883 21.9254 13.202 21.7916 13.1753C21.6577 13.1487 21.5348 13.083 21.4383 12.9865C21.3418 12.89 21.2761 12.7671 21.2495 12.6332C21.2228 12.4994 21.2365 12.3606 21.2887 12.2345C21.341 12.1085 21.4294 12.0007 21.5429 11.9249C21.6563 11.8491 21.7897 11.8086 21.9262 11.8086C22.1092 11.8086 22.2847 11.8813 22.4141 12.0107C22.5435 12.1401 22.6162 12.3156 22.6162 12.4986Z"
					fill={ color || '#013660' }/>
				<path
					d="M1.38 12.4986C1.38 12.6351 1.33953 12.7685 1.26371 12.8819C1.1879 12.9954 1.08013 13.0838 0.954052 13.1361C0.827971 13.1883 0.689235 13.202 0.555388 13.1753C0.421541 13.1487 0.298595 13.083 0.202097 12.9865C0.105598 12.89 0.0398823 12.7671 0.0132585 12.6332C-0.0133653 12.4994 0.000298966 12.3606 0.0525234 12.2345C0.104748 12.1085 0.193187 12.0007 0.306657 11.9249C0.420127 11.8491 0.553531 11.8086 0.69 11.8086C0.872696 11.8096 1.04763 11.8826 1.17682 12.0118C1.306 12.141 1.37902 12.3159 1.38 12.4986Z"
					fill={ color || '#013660' }/>
				<path
					d="M16.6275 12.4989C16.6275 12.8423 16.5257 13.178 16.3349 13.4636C16.1441 13.7491 15.873 13.9716 15.5557 14.103C15.2385 14.2344 14.8894 14.2688 14.5526 14.2018C14.2158 14.1348 13.9064 13.9695 13.6636 13.7267C13.4207 13.4838 13.2554 13.1745 13.1884 12.8377C13.1214 12.5009 13.1558 12.1518 13.2872 11.8345C13.4186 11.5173 13.6411 11.2461 13.9267 11.0553C14.2122 10.8645 14.5479 10.7627 14.8913 10.7627C15.1194 10.7622 15.3454 10.8068 15.5563 10.8939C15.7672 10.9809 15.9588 11.1088 16.1201 11.2701C16.2814 11.4315 16.4093 11.6231 16.4964 11.8339C16.5835 12.0448 16.628 12.2708 16.6275 12.4989Z"
					fill={ color || '#013660' }/>
				<path
					d="M9.45749 12.4989C9.45749 12.8425 9.35556 13.1783 9.16461 13.4639C8.97367 13.7496 8.70228 13.9721 8.3848 14.1034C8.06732 14.2347 7.71803 14.2689 7.38113 14.2016C7.04423 14.1343 6.73486 13.9685 6.49219 13.7253C6.24953 13.4821 6.08446 13.1724 6.01789 12.8354C5.95132 12.4983 5.98624 12.1491 6.11823 11.8319C6.25022 11.5147 6.47334 11.2438 6.75936 11.0535C7.04538 10.8632 7.38143 10.762 7.72499 10.7627C7.95282 10.7627 8.17841 10.8076 8.38885 10.8949C8.59929 10.9822 8.79045 11.1102 8.95138 11.2715C9.1123 11.4327 9.23984 11.6242 9.32669 11.8348C9.41353 12.0454 9.45798 12.2711 9.45749 12.4989Z"
					fill={ color || '#013660' }/>
				<path
					d="M11.3097 7.17774C11.6531 7.17774 11.9888 7.27957 12.2743 7.47035C12.5599 7.66113 12.7824 7.9323 12.9138 8.24955C13.0452 8.56681 13.0796 8.91591 13.0126 9.25271C12.9456 9.58951 12.7803 9.89888 12.5375 10.1417C12.2946 10.3845 11.9853 10.5499 11.6485 10.6169C11.3117 10.6839 10.9626 10.6495 10.6453 10.5181C10.328 10.3867 10.0569 10.1641 9.8661 9.8786C9.67532 9.59307 9.57349 9.25739 9.57349 8.91399C9.573 8.68584 9.61757 8.45984 9.70465 8.24897C9.79173 8.03809 9.9196 7.8465 10.0809 7.68517C10.2422 7.52385 10.4338 7.39598 10.6447 7.3089C10.8556 7.22182 11.0816 7.17724 11.3097 7.17774Z"
					fill={ color || '#013660' }/>
				<path
					d="M11.3097 14.3477C11.6533 14.3477 11.9891 14.4496 12.2747 14.6405C12.5603 14.8315 12.7829 15.1029 12.9142 15.4203C13.0455 15.7378 13.0796 16.0871 13.0123 16.424C12.9451 16.7609 12.7793 17.0703 12.5361 17.313C12.2929 17.5556 11.9832 17.7207 11.6462 17.7873C11.3091 17.8538 10.9599 17.8189 10.6427 17.6869C10.3255 17.5549 10.0546 17.3318 9.86428 17.0458C9.67395 16.7598 9.57275 16.4237 9.57349 16.0802C9.57349 15.8523 9.61843 15.6267 9.70573 15.4163C9.79303 15.2059 9.92098 15.0147 10.0823 14.8538C10.2435 14.6928 10.435 14.5653 10.6456 14.4785C10.8562 14.3916 11.0819 14.3472 11.3097 14.3477Z"
					fill={ color || '#013660' }/>
				<path
					d="M11.3099 4.00488C11.5621 4.00488 11.8086 4.07966 12.0183 4.21976C12.2279 4.35986 12.3914 4.55899 12.4879 4.79196C12.5844 5.02494 12.6096 5.2813 12.5604 5.52862C12.5112 5.77595 12.3898 6.00313 12.2115 6.18144C12.0332 6.35976 11.806 6.48119 11.5587 6.53038C11.3113 6.57958 11.055 6.55433 10.822 6.45783C10.589 6.36133 10.3899 6.19791 10.2498 5.98823C10.1097 5.77856 10.0349 5.53205 10.0349 5.27988C10.0349 5.11245 10.0679 4.94665 10.132 4.79196C10.196 4.63727 10.29 4.49672 10.4084 4.37832C10.6475 4.13921 10.9718 4.00488 11.3099 4.00488Z"
					fill={ color || '#013660' }/>
				<path
					d="M11.3099 18.4424C11.5622 18.4424 11.8089 18.5173 12.0187 18.6575C12.2284 18.7978 12.3918 18.9971 12.4882 19.2303C12.5846 19.4635 12.6096 19.7201 12.5601 19.9675C12.5106 20.2149 12.3888 20.4421 12.2101 20.6203C12.0315 20.7984 11.8039 20.9196 11.5564 20.9683C11.3088 21.0171 11.0523 20.9913 10.8194 20.8943C10.5865 20.7972 10.3876 20.6332 10.248 20.423C10.1083 20.2128 10.0342 19.966 10.0349 19.7136C10.0349 19.5464 10.0679 19.3808 10.132 19.2263C10.1962 19.0718 10.2901 18.9315 10.4086 18.8134C10.527 18.6953 10.6676 18.6017 10.8223 18.5381C10.9769 18.4744 11.1427 18.4419 11.3099 18.4424Z"
					fill={ color || '#013660' }/>
				<path
					d="M11.3101 1.18833C11.4468 1.18833 11.5803 1.2289 11.6939 1.30488C11.8074 1.38087 11.8958 1.48885 11.948 1.61515C12.0001 1.74145 12.0135 1.88038 11.9866 2.01433C11.9597 2.14828 11.8936 2.27121 11.7967 2.36756C11.6998 2.46391 11.5765 2.52933 11.4424 2.55553C11.3083 2.58173 11.1695 2.56752 11.0435 2.51472C10.9175 2.46192 10.81 2.3729 10.7346 2.25894C10.6592 2.14498 10.6194 2.01121 10.6201 1.87458C10.6196 1.78383 10.6371 1.69388 10.6716 1.60994C10.7061 1.526 10.7569 1.44974 10.8211 1.38557C10.8853 1.3214 10.9615 1.27059 11.0455 1.23609C11.1294 1.20159 11.2194 1.18408 11.3101 1.18458"
					fill={ color || '#013660' }/>
				<path
					d="M11.3098 22.4248C11.4462 22.4248 11.5796 22.4653 11.6931 22.5411C11.8066 22.6169 11.895 22.7247 11.9472 22.8508C11.9995 22.9768 12.0131 23.1156 11.9865 23.2494C11.9599 23.3833 11.8942 23.5062 11.7977 23.6027C11.7012 23.6992 11.5782 23.7649 11.4444 23.7915C11.3105 23.8182 11.1718 23.8045 11.0457 23.7523C10.9196 23.7001 10.8119 23.6116 10.736 23.4981C10.6602 23.3847 10.6198 23.2513 10.6198 23.1148C10.6198 22.9318 10.6924 22.7563 10.8218 22.6269C10.9512 22.4975 11.1268 22.4248 11.3098 22.4248Z"
					fill={ color || '#013660' }/>
				<path
					d="M19.4288 16.08C19.431 16.2965 19.3688 16.5087 19.2501 16.6897C19.1314 16.8707 18.9615 17.0123 18.7621 17.0965C18.5627 17.1807 18.3428 17.2038 18.1302 17.1626C17.9177 17.1215 17.7222 17.0181 17.5686 16.8655C17.415 16.713 17.3103 16.5182 17.2677 16.306C17.2251 16.0938 17.2466 15.8737 17.3294 15.6737C17.4122 15.4737 17.5527 15.3029 17.7328 15.1829C17.913 15.0629 18.1248 14.9993 18.3413 15C18.4844 15 18.6261 15.0283 18.7583 15.0831C18.8905 15.138 19.0106 15.2185 19.1116 15.3199C19.2126 15.4212 19.2926 15.5416 19.3471 15.6739C19.4015 15.8063 19.4293 15.9481 19.4288 16.0913"
					fill={ color || '#013660' }/>
				<path
					d="M5.34724 16.08C5.34947 16.2965 5.28727 16.5087 5.16856 16.6897C5.04985 16.8707 4.87999 17.0123 4.68058 17.0965C4.48116 17.1807 4.26121 17.2038 4.04869 17.1626C3.83617 17.1215 3.64068 17.0181 3.48709 16.8655C3.3335 16.713 3.22874 16.5182 3.18614 16.306C3.14354 16.0938 3.16502 15.8737 3.24785 15.6737C3.33068 15.4737 3.47111 15.3029 3.6513 15.1829C3.83148 15.0629 4.04327 14.9993 4.25974 15C4.40287 15 4.54459 15.0283 4.67677 15.0831C4.80896 15.138 4.92901 15.2185 5.03004 15.3199C5.13108 15.4212 5.2111 15.5416 5.26554 15.6739C5.31997 15.8063 5.34773 15.9481 5.34724 16.0913"
					fill={ color || '#013660' }/>
				<path
					d="M19.4475 8.91758C19.4475 9.1342 19.383 9.34591 19.2622 9.52575C19.1415 9.70559 18.9699 9.84542 18.7694 9.92743C18.5689 10.0094 18.3486 10.0299 18.1364 9.98629C17.9242 9.94264 17.7298 9.83683 17.578 9.68234C17.4261 9.52784 17.3237 9.33165 17.2837 9.11875C17.2438 8.90585 17.2681 8.68587 17.3536 8.48682C17.439 8.28778 17.5818 8.11868 17.7637 8.00106C17.9456 7.88343 18.1584 7.82261 18.375 7.82633C18.5185 7.82584 18.6606 7.85373 18.7932 7.90839C18.9258 7.96305 19.0463 8.04342 19.1477 8.14485C19.2492 8.24628 19.3295 8.36677 19.3842 8.49939C19.4389 8.63201 19.4668 8.77414 19.4663 8.91758"
					fill={ color || '#013660' }/>
				<path
					d="M5.36634 8.91743C5.36634 9.13326 5.30234 9.34424 5.18243 9.52369C5.06252 9.70315 4.89209 9.84302 4.69269 9.92561C4.49329 10.0082 4.27388 10.0298 4.0622 9.98771C3.85051 9.9456 3.65607 9.84167 3.50346 9.68906C3.35084 9.53644 3.24691 9.342 3.20481 9.13032C3.1627 8.91864 3.18431 8.69922 3.2669 8.49982C3.3495 8.30042 3.48937 8.13 3.66882 8.01009C3.84828 7.89018 4.05926 7.82618 4.27509 7.82618C4.41853 7.82568 4.56066 7.85357 4.69328 7.90824C4.8259 7.9629 4.94639 8.04326 5.04782 8.14469C5.14925 8.24612 5.22962 8.36662 5.28428 8.49924C5.33895 8.63186 5.36683 8.77398 5.36634 8.91743Z"
					fill={ color || '#013660' }/>
				<path
					d="M7.72516 20.6249C7.50933 20.6249 7.29835 20.5609 7.1189 20.441C6.93944 20.3211 6.79957 20.1506 6.71698 19.9512C6.63438 19.7518 6.61277 19.5324 6.65488 19.3207C6.69699 19.1091 6.80092 18.9146 6.95353 18.762C7.10615 18.6094 7.30059 18.5055 7.51227 18.4634C7.72395 18.4212 7.94337 18.4429 8.14277 18.5254C8.34217 18.608 8.5126 18.7479 8.6325 18.9274C8.75241 19.1068 8.81641 19.3178 8.81641 19.5336C8.81641 19.823 8.70144 20.1006 8.49679 20.3053C8.29214 20.5099 8.01458 20.6249 7.72516 20.6249Z"
					fill={ color || '#013660' }/>
				<path
					d="M7.72516 6.53602C7.50917 6.53602 7.29804 6.47192 7.1185 6.35184C6.93897 6.23177 6.7991 6.06112 6.71662 5.8615C6.63413 5.66188 6.61274 5.44227 6.65516 5.23049C6.69757 5.01871 6.80187 4.82427 6.95486 4.67181C7.10785 4.51935 7.30264 4.41571 7.51457 4.37403C7.7265 4.33235 7.94603 4.35449 8.14536 4.43766C8.3447 4.52083 8.51487 4.66128 8.63432 4.84123C8.75378 5.02118 8.81715 5.23253 8.81641 5.44852C8.81641 5.59164 8.78815 5.73337 8.73327 5.86555C8.67838 5.99774 8.59794 6.11779 8.49656 6.21882C8.39518 6.31986 8.27485 6.39988 8.14248 6.45431C8.0101 6.50874 7.86828 6.53651 7.72516 6.53602Z"
					fill={ color || '#013660' }/>
				<path
					d="M14.8913 20.6249C14.6751 20.6256 14.4636 20.5622 14.2836 20.4425C14.1036 20.3229 13.9631 20.1525 13.88 19.953C13.797 19.7534 13.7751 19.5337 13.8171 19.3217C13.859 19.1096 13.963 18.9148 14.1159 18.762C14.2687 18.6092 14.4635 18.5052 14.6755 18.4632C14.8876 18.4212 15.1073 18.4431 15.3068 18.5262C15.5064 18.6092 15.6768 18.7497 15.7964 18.9297C15.916 19.1097 15.9795 19.3212 15.9788 19.5374C15.9788 19.8258 15.8642 20.1024 15.6602 20.3064C15.4563 20.5103 15.1797 20.6249 14.8913 20.6249Z"
					fill={ color || '#013660' }/>
				<path
					d="M14.8913 6.55456C14.6753 6.55531 14.4639 6.49194 14.284 6.37248C14.104 6.25303 13.9636 6.08286 13.8804 5.88352C13.7972 5.68419 13.7751 5.46466 13.8168 5.25273C13.8585 5.0408 13.9621 4.84601 14.1146 4.69302C14.267 4.54003 14.4615 4.43573 14.6732 4.39331C14.885 4.3509 15.1046 4.37229 15.3042 4.45478C15.5039 4.53726 15.6745 4.67712 15.7946 4.85666C15.9147 5.0362 15.9788 5.24733 15.9788 5.46331C15.9788 5.75208 15.8643 6.02908 15.6605 6.23362C15.4566 6.43816 15.18 6.55357 14.8913 6.55456Z"
					fill={ color || '#013660' }/>
				<path
					d="M19.1736 5.39291C19.1735 5.55539 19.1251 5.71416 19.0344 5.84898C18.9437 5.98379 18.8149 6.08852 18.6644 6.14981C18.514 6.21109 18.3486 6.22615 18.1896 6.19306C18.0305 6.15997 17.8849 6.08022 17.7713 5.96401C17.6578 5.8478 17.5815 5.70039 17.5521 5.54059C17.5227 5.3808 17.5416 5.21587 17.6063 5.06686C17.6711 4.91784 17.7788 4.79151 17.9157 4.70398C18.0525 4.61644 18.2124 4.57168 18.3748 4.57541C18.5913 4.5764 18.7987 4.66284 18.9518 4.81594C19.1049 4.96904 19.1913 5.1764 19.1923 5.39291"
					fill={ color || '#013660' }/>
				<path
					d="M5.15612 5.3927C5.15612 5.55438 5.10817 5.71244 5.01835 5.84687C4.92852 5.98131 4.80084 6.08609 4.65146 6.14797C4.50208 6.20984 4.33771 6.22603 4.17913 6.19449C4.02055 6.16294 3.87489 6.08508 3.76056 5.97076C3.64623 5.85643 3.56837 5.71076 3.53683 5.55218C3.50528 5.3936 3.52147 5.22923 3.58335 5.07985C3.64522 4.93047 3.75 4.8028 3.88444 4.71297C4.01888 4.62314 4.17693 4.5752 4.33862 4.5752C4.55543 4.5752 4.76337 4.66132 4.91668 4.81464C5.06999 4.96795 5.15612 5.17588 5.15612 5.3927Z"
					fill={ color || '#013660' }/>
				<path
					d="M19.0948 19.6054C19.0941 19.767 19.0455 19.9246 18.9552 20.0586C18.865 20.1925 18.737 20.2967 18.5876 20.358C18.4381 20.4193 18.2739 20.435 18.1156 20.403C17.9572 20.371 17.8119 20.2929 17.698 20.1784C17.584 20.0639 17.5065 19.9183 17.4753 19.7598C17.444 19.6013 17.4604 19.4371 17.5224 19.288C17.5844 19.1388 17.6892 19.0114 17.8235 18.9217C17.9579 18.832 18.1158 18.7842 18.2773 18.7842C18.4945 18.7852 18.7024 18.8721 18.8556 19.026C19.0088 19.1799 19.0948 19.3883 19.0948 19.6054Z"
					fill={ color || '#013660' }/>
				<path
					d="M5.07737 19.6054C5.07663 19.767 5.02806 19.9246 4.93778 20.0586C4.8475 20.1925 4.71957 20.2967 4.57013 20.358C4.42069 20.4193 4.25643 20.435 4.0981 20.403C3.93977 20.371 3.79446 20.2929 3.6805 20.1784C3.56655 20.0639 3.48906 19.9183 3.45782 19.7598C3.42658 19.6013 3.44299 19.4371 3.50497 19.288C3.56695 19.1388 3.67173 19.0114 3.80609 18.9217C3.94044 18.832 4.09835 18.7842 4.25987 18.7842C4.36755 18.7842 4.47416 18.8054 4.57359 18.8468C4.67302 18.8881 4.7633 18.9486 4.83926 19.0249C4.91523 19.1013 4.97536 19.1918 5.01623 19.2914C5.05709 19.3911 5.07787 19.4978 5.07737 19.6054Z"
					fill={ color || '#013660' }/>
				<path
					d="M22.6162 8.89512C22.6162 9.01749 22.5799 9.13712 22.5119 9.23888C22.4439 9.34063 22.3473 9.41994 22.2342 9.46677C22.1211 9.5136 21.9967 9.52585 21.8767 9.50198C21.7567 9.4781 21.6464 9.41917 21.5599 9.33264C21.4734 9.24611 21.4144 9.13586 21.3906 9.01583C21.3667 8.8958 21.3789 8.77139 21.4258 8.65833C21.4726 8.54527 21.5519 8.44863 21.6537 8.38065C21.7554 8.31266 21.875 8.27637 21.9974 8.27637C22.1615 8.27637 22.3189 8.34156 22.4349 8.4576C22.551 8.57363 22.6162 8.73101 22.6162 8.89512Z"
					fill={ color || '#013660' }/>
				<path
					d="M1.2375 8.89512C1.2375 9.01749 1.20121 9.13712 1.13322 9.23888C1.06523 9.34063 0.968597 9.41994 0.855536 9.46677C0.742474 9.5136 0.618064 9.52585 0.498038 9.50198C0.378012 9.4781 0.267762 9.41917 0.181228 9.33264C0.0946942 9.24611 0.035764 9.13586 0.0118894 9.01583C-0.0119852 8.8958 0.000268095 8.77139 0.0470998 8.65833C0.0939315 8.54527 0.173238 8.44863 0.274991 8.38065C0.376744 8.31266 0.496373 8.27637 0.61875 8.27637C0.782853 8.27637 0.940234 8.34156 1.05627 8.4576C1.17231 8.57363 1.2375 8.73101 1.2375 8.89512Z"
					fill={ color || '#013660' }/>
				<path
					d="M22.6162 16.0992C22.6162 16.2216 22.5799 16.3412 22.5119 16.443C22.4439 16.5447 22.3473 16.624 22.2342 16.6709C22.1211 16.7177 21.9967 16.73 21.8767 16.7061C21.7567 16.6822 21.6464 16.6233 21.5599 16.5367C21.4734 16.4502 21.4144 16.34 21.3906 16.2199C21.3667 16.0999 21.3789 15.9755 21.4258 15.8624C21.4726 15.7494 21.5519 15.6527 21.6537 15.5847C21.7554 15.5168 21.875 15.4805 21.9974 15.4805C22.1615 15.4805 22.3189 15.5457 22.4349 15.6617C22.551 15.7777 22.6162 15.9351 22.6162 16.0992Z"
					fill={ color || '#013660' }/>
				<path
					d="M1.2375 16.0992C1.2375 16.2216 1.20121 16.3412 1.13322 16.443C1.06523 16.5447 0.968597 16.624 0.855536 16.6709C0.742474 16.7177 0.618064 16.73 0.498038 16.7061C0.378012 16.6822 0.267762 16.6233 0.181228 16.5367C0.0946942 16.4502 0.035764 16.34 0.0118894 16.2199C-0.0119852 16.0999 0.000268095 15.9755 0.0470998 15.8624C0.0939315 15.7494 0.173238 15.6527 0.274991 15.5847C0.376744 15.5168 0.496373 15.4805 0.61875 15.4805C0.782853 15.4805 0.940234 15.5457 1.05627 15.6617C1.17231 15.7777 1.2375 15.9351 1.2375 16.0992Z"
					fill={ color || '#013660' }/>
				<path
					d="M7.66868 1.18848C7.79105 1.18848 7.91068 1.22477 8.01244 1.29275C8.11419 1.36074 8.1935 1.45738 8.24033 1.57044C8.28716 1.6835 8.29941 1.80791 8.27554 1.92794C8.25166 2.04796 8.19273 2.15822 8.1062 2.24475C8.01967 2.33128 7.90941 2.39021 7.78939 2.41409C7.66936 2.43796 7.54495 2.42571 7.43189 2.37888C7.31883 2.33205 7.22219 2.25274 7.15421 2.15099C7.08622 2.04923 7.04993 1.9296 7.04993 1.80723C7.04993 1.64312 7.11512 1.48574 7.23115 1.3697C7.34719 1.25367 7.50457 1.18848 7.66868 1.18848Z"
					fill={ color || '#013660' }/>
				<path
					d="M7.66868 22.5674C7.79105 22.5674 7.91068 22.6037 8.01244 22.6717C8.11419 22.7397 8.1935 22.8363 8.24033 22.9493C8.28716 23.0624 8.29941 23.1868 8.27554 23.3068C8.25166 23.4269 8.19273 23.5371 8.1062 23.6237C8.01967 23.7102 7.90941 23.7691 7.78939 23.793C7.66936 23.8169 7.54495 23.8046 7.43189 23.7578C7.31883 23.711 7.22219 23.6316 7.15421 23.5299C7.08622 23.4281 7.04993 23.3085 7.04993 23.1861C7.04993 23.022 7.11512 22.8646 7.23115 22.7486C7.34719 22.6326 7.50457 22.5674 7.66868 22.5674Z"
					fill={ color || '#013660' }/>
				<path
					d="M14.8762 1.18848C14.9983 1.18996 15.1172 1.2275 15.2179 1.29638C15.3187 1.36526 15.3968 1.46241 15.4425 1.57559C15.4882 1.68878 15.4994 1.81294 15.4747 1.93247C15.45 2.052 15.3905 2.16155 15.3036 2.24733C15.2168 2.33312 15.1065 2.39131 14.9867 2.41458C14.8669 2.43785 14.7429 2.42517 14.6303 2.37812C14.5176 2.33108 14.4214 2.25178 14.3538 2.15019C14.2861 2.04861 14.25 1.92928 14.25 1.80723C14.25 1.64312 14.3152 1.48574 14.4312 1.3697C14.5473 1.25367 14.7046 1.18848 14.8688 1.18848"
					fill={ color || '#013660' }/>
				<path
					d="M14.8762 22.5674C14.9983 22.5689 15.1172 22.6064 15.2179 22.6753C15.3187 22.7442 15.3968 22.8413 15.4425 22.9545C15.4882 23.0677 15.4994 23.1918 15.4747 23.3114C15.45 23.4309 15.3905 23.5405 15.3036 23.6262C15.2168 23.712 15.1065 23.7702 14.9867 23.7935C14.8669 23.8168 14.7429 23.8041 14.6303 23.757C14.5176 23.71 14.4214 23.6307 14.3538 23.5291C14.2861 23.4275 14.25 23.3082 14.25 23.1861C14.25 23.022 14.3152 22.8646 14.4312 22.7486C14.5473 22.6326 14.7046 22.5674 14.8688 22.5674"
					fill={ color || '#013660' }/>
				<path
					d="M22.2864 19.5979C22.2871 19.6626 22.2686 19.726 22.2332 19.7802C22.1978 19.8343 22.1472 19.8767 22.0876 19.902C22.0281 19.9273 21.9624 19.9343 21.8988 19.9221C21.8353 19.9099 21.7768 19.8792 21.7308 19.8337C21.6848 19.7882 21.6534 19.7301 21.6405 19.6667C21.6276 19.6033 21.6338 19.5375 21.6584 19.4777C21.683 19.4179 21.7248 19.3667 21.7785 19.3307C21.8322 19.2946 21.8955 19.2754 21.9601 19.2754C22.0028 19.2749 22.0451 19.2829 22.0847 19.2989C22.1243 19.3149 22.1603 19.3385 22.1906 19.3685C22.221 19.3985 22.245 19.4343 22.2615 19.4736C22.2779 19.513 22.2864 19.5552 22.2864 19.5979Z"
					fill={ color || '#013660' }/>
				<path
					d="M1.05018 19.6682C1.05093 19.7331 1.03233 19.7967 0.996766 19.8509C0.961204 19.9051 0.910291 19.9475 0.850526 19.9727C0.790761 19.9978 0.724855 20.0046 0.661222 19.9921C0.597588 19.9796 0.539113 19.9485 0.493262 19.9026C0.44741 19.8568 0.416262 19.7983 0.403793 19.7347C0.391325 19.6711 0.398101 19.6051 0.423258 19.5454C0.448414 19.4856 0.49081 19.4347 0.545032 19.3991C0.599255 19.3636 0.662844 19.345 0.727684 19.3457C0.812914 19.3467 0.894378 19.381 0.954649 19.4413C1.01492 19.5015 1.04921 19.583 1.05018 19.6682Z"
					fill={ color || '#013660' }/>
				<path
					d="M22.2864 5.70729C22.2871 5.77213 22.2685 5.83572 22.233 5.88994C22.1974 5.94416 22.1465 5.98656 22.0867 6.01171C22.027 6.03687 21.9611 6.04365 21.8974 6.03118C21.8338 6.01871 21.7753 5.98756 21.7295 5.94171C21.6836 5.89586 21.6525 5.83738 21.64 5.77375C21.6275 5.71012 21.6343 5.64421 21.6595 5.58444C21.6846 5.52468 21.727 5.47377 21.7812 5.43821C21.8355 5.40264 21.8991 5.38405 21.9639 5.38479C22.0491 5.38576 22.1306 5.42005 22.1909 5.48032C22.2511 5.54059 22.2854 5.62206 22.2864 5.70729Z"
					fill={ color || '#013660' }/>
				<path
					d="M1.05018 5.78248C1.05093 5.84732 1.03233 5.91091 0.996766 5.96513C0.961204 6.01936 0.910291 6.06175 0.850526 6.08691C0.790761 6.11206 0.724855 6.11884 0.661222 6.10637C0.597588 6.0939 0.539113 6.06276 0.493262 6.0169C0.44741 5.97105 0.416262 5.91258 0.403793 5.84894C0.391325 5.78531 0.398101 5.7194 0.423258 5.65964C0.448414 5.59987 0.49081 5.54896 0.545032 5.5134C0.599255 5.47784 0.662844 5.45924 0.727684 5.45998C0.813216 5.45998 0.895245 5.49396 0.955726 5.55444C1.01621 5.61492 1.05018 5.69695 1.05018 5.78248Z"
					fill={ color || '#013660' }/>
				<path
					d="M18.3749 1.54885C18.4397 1.54811 18.5033 1.56671 18.5575 1.60227C18.6118 1.63783 18.6542 1.68874 18.6793 1.74851C18.7045 1.80827 18.7112 1.87418 18.6988 1.93781C18.6863 2.00145 18.6552 2.05992 18.6093 2.10577C18.5635 2.15162 18.505 2.18277 18.4414 2.19524C18.3777 2.20771 18.3118 2.20093 18.252 2.17578C18.1923 2.15062 18.1414 2.10822 18.1058 2.054C18.0702 1.99978 18.0516 1.93619 18.0524 1.87135C18.0534 1.78612 18.0877 1.70466 18.1479 1.64438C18.2082 1.58411 18.2897 1.54982 18.3749 1.54885Z"
					fill={ color || '#013660' }/>
				<path
					d="M18.3112 22.7852C18.376 22.7844 18.4396 22.803 18.4938 22.8386C18.548 22.8742 18.5904 22.9251 18.6156 22.9848C18.6408 23.0446 18.6475 23.1105 18.6351 23.1741C18.6226 23.2378 18.5914 23.2962 18.5456 23.3421C18.4997 23.388 18.4413 23.4191 18.3776 23.4316C18.314 23.444 18.2481 23.4373 18.1883 23.4121C18.1286 23.3869 18.0776 23.3446 18.0421 23.2903C18.0065 23.2361 17.9879 23.1725 17.9887 23.1077C17.9896 23.0224 18.0239 22.941 18.0842 22.8807C18.1445 22.8204 18.2259 22.7862 18.3112 22.7852Z"
					fill={ color || '#013660' }/>
				<path
					d="M4.37611 1.54885C4.44095 1.54811 4.50454 1.56671 4.55876 1.60227C4.61298 1.63783 4.65538 1.68874 4.68054 1.74851C4.70569 1.80827 4.71247 1.87418 4.7 1.93781C4.68753 2.00145 4.65638 2.05992 4.61053 2.10577C4.56468 2.15162 4.50621 2.18277 4.44257 2.19524C4.37894 2.20771 4.31303 2.20093 4.25327 2.17578C4.1935 2.15062 4.14259 2.10822 4.10703 2.054C4.07147 1.99978 4.05287 1.93619 4.05361 1.87135C4.05361 1.78582 4.08759 1.70379 4.14807 1.64331C4.20855 1.58283 4.29058 1.54885 4.37611 1.54885Z"
					fill={ color || '#013660' }/>
				<path
					d="M4.37611 22.7852C4.44095 22.7844 4.50454 22.803 4.55876 22.8386C4.61298 22.8742 4.65538 22.9251 4.68054 22.9848C4.70569 23.0446 4.71247 23.1105 4.7 23.1741C4.68753 23.2378 4.65638 23.2962 4.61053 23.3421C4.56468 23.388 4.50621 23.4191 4.44257 23.4316C4.37894 23.444 4.31303 23.4373 4.25327 23.4121C4.1935 23.3869 4.14259 23.3446 4.10703 23.2903C4.07147 23.2361 4.05287 23.1725 4.05361 23.1077C4.05361 23.0221 4.08759 22.9401 4.14807 22.8796C4.20855 22.8192 4.29058 22.7852 4.37611 22.7852Z"
					fill={ color || '#013660' }/>
			</g>
			<defs>
				<clipPath id="clip0_4771_109091">
					<rect width="117.75" height="24" fill="white"/>
				</clipPath>
			</defs>
		</svg>

	);
}

export default LogoSvg;
